.alert {
    border: 0px;
    border-radius: 0;
    a, .alert-link {
        color: #FFFFFF;
    }
    .variations(~"", background-color, #FFFFFF);
    &-info, &-danger, &-warning, &-success {
        color: #FFFFFF;
    }
    &-default {
        a, .alert-link {
            color: #000000;
        }
    }
}
