@font-face {
    font-family: "Material-Design-Icons";
    src:url("@{material-font-path}/Material-Design-Icons.eot?-g7cqhn");
    src:url("@{material-font-path}/Material-Design-Icons.eot?#iefix-g7cqhn") format("embedded-opentype"),
        url("@{material-font-path}/Material-Design-Icons.woff?-g7cqhn") format("woff"),
        url("@{material-font-path}/Material-Design-Icons.ttf?-g7cqhn") format("truetype"),
        url("@{material-font-path}/Material-Design-Icons.svg?-g7cqhn#Material-Design-Icons") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="mdi-"], [class*=" mdi-"] {
    font-family: "Material-Design-Icons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.mdi-action-3d-rotation:before {
    content: "\e600";
}
.mdi-action-accessibility:before {
    content: "\e601";
}
.mdi-action-account-balance:before {
    content: "\e602";
}
.mdi-action-account-balance-wallet:before {
    content: "\e603";
}
.mdi-action-account-box:before {
    content: "\e604";
}
.mdi-action-account-child:before {
    content: "\e605";
}
.mdi-action-account-circle:before {
    content: "\e606";
}
.mdi-action-add-shopping-cart:before {
    content: "\e607";
}
.mdi-action-alarm:before {
    content: "\e608";
}
.mdi-action-alarm-add:before {
    content: "\e609";
}
.mdi-action-alarm-off:before {
    content: "\e60a";
}
.mdi-action-alarm-on:before {
    content: "\e60b";
}
.mdi-action-android:before {
    content: "\e60c";
}
.mdi-action-announcement:before {
    content: "\e60d";
}
.mdi-action-aspect-ratio:before {
    content: "\e60e";
}
.mdi-action-assessment:before {
    content: "\e60f";
}
.mdi-action-assignment:before {
    content: "\e610";
}
.mdi-action-assignment-ind:before {
    content: "\e611";
}
.mdi-action-assignment-late:before {
    content: "\e612";
}
.mdi-action-assignment-return:before {
    content: "\e613";
}
.mdi-action-assignment-returned:before {
    content: "\e614";
}
.mdi-action-assignment-turned-in:before {
    content: "\e615";
}
.mdi-action-autorenew:before {
    content: "\e616";
}
.mdi-action-backup:before {
    content: "\e617";
}
.mdi-action-book:before {
    content: "\e618";
}
.mdi-action-bookmark:before {
    content: "\e619";
}
.mdi-action-bookmark-outline:before {
    content: "\e61a";
}
.mdi-action-bug-report:before {
    content: "\e61b";
}
.mdi-action-cached:before {
    content: "\e61c";
}
.mdi-action-class:before {
    content: "\e61d";
}
.mdi-action-credit-card:before {
    content: "\e61e";
}
.mdi-action-dashboard:before {
    content: "\e61f";
}
.mdi-action-delete:before {
    content: "\e620";
}
.mdi-action-description:before {
    content: "\e621";
}
.mdi-action-dns:before {
    content: "\e622";
}
.mdi-action-done:before {
    content: "\e623";
}
.mdi-action-done-all:before {
    content: "\e624";
}
.mdi-action-event:before {
    content: "\e625";
}
.mdi-action-exit-to-app:before {
    content: "\e626";
}
.mdi-action-explore:before {
    content: "\e627";
}
.mdi-action-extension:before {
    content: "\e628";
}
.mdi-action-face-unlock:before {
    content: "\e629";
}
.mdi-action-favorite:before {
    content: "\e62a";
}
.mdi-action-favorite-outline:before {
    content: "\e62b";
}
.mdi-action-find-in-page:before {
    content: "\e62c";
}
.mdi-action-find-replace:before {
    content: "\e62d";
}
.mdi-action-flip-to-back:before {
    content: "\e62e";
}
.mdi-action-flip-to-front:before {
    content: "\e62f";
}
.mdi-action-get-app:before {
    content: "\e630";
}
.mdi-action-grade:before {
    content: "\e631";
}
.mdi-action-group-work:before {
    content: "\e632";
}
.mdi-action-help:before {
    content: "\e633";
}
.mdi-action-highlight-remove:before {
    content: "\e634";
}
.mdi-action-history:before {
    content: "\e635";
}
.mdi-action-home:before {
    content: "\e636";
}
.mdi-action-https:before {
    content: "\e637";
}
.mdi-action-info:before {
    content: "\e638";
}
.mdi-action-info-outline:before {
    content: "\e639";
}
.mdi-action-input:before {
    content: "\e63a";
}
.mdi-action-invert-colors:before {
    content: "\e63b";
}
.mdi-action-label:before {
    content: "\e63c";
}
.mdi-action-label-outline:before {
    content: "\e63d";
}
.mdi-action-language:before {
    content: "\e63e";
}
.mdi-action-launch:before {
    content: "\e63f";
}
.mdi-action-list:before {
    content: "\e640";
}
.mdi-action-lock:before {
    content: "\e641";
}
.mdi-action-lock-open:before {
    content: "\e642";
}
.mdi-action-lock-outline:before {
    content: "\e643";
}
.mdi-action-loyalty:before {
    content: "\e644";
}
.mdi-action-markunread-mailbox:before {
    content: "\e645";
}
.mdi-action-note-add:before {
    content: "\e646";
}
.mdi-action-open-in-browser:before {
    content: "\e647";
}
.mdi-action-open-in-new:before {
    content: "\e648";
}
.mdi-action-open-with:before {
    content: "\e649";
}
.mdi-action-pageview:before {
    content: "\e64a";
}
.mdi-action-payment:before {
    content: "\e64b";
}
.mdi-action-perm-camera-mic:before {
    content: "\e64c";
}
.mdi-action-perm-contact-cal:before {
    content: "\e64d";
}
.mdi-action-perm-data-setting:before {
    content: "\e64e";
}
.mdi-action-perm-device-info:before {
    content: "\e64f";
}
.mdi-action-perm-identity:before {
    content: "\e650";
}
.mdi-action-perm-media:before {
    content: "\e651";
}
.mdi-action-perm-phone-msg:before {
    content: "\e652";
}
.mdi-action-perm-scan-wifi:before {
    content: "\e653";
}
.mdi-action-picture-in-picture:before {
    content: "\e654";
}
.mdi-action-polymer:before {
    content: "\e655";
}
.mdi-action-print:before {
    content: "\e656";
}
.mdi-action-query-builder:before {
    content: "\e657";
}
.mdi-action-question-answer:before {
    content: "\e658";
}
.mdi-action-receipt:before {
    content: "\e659";
}
.mdi-action-redeem:before {
    content: "\e65a";
}
.mdi-action-report-problem:before {
    content: "\e65b";
}
.mdi-action-restore:before {
    content: "\e65c";
}
.mdi-action-room:before {
    content: "\e65d";
}
.mdi-action-schedule:before {
    content: "\e65e";
}
.mdi-action-search:before {
    content: "\e65f";
}
.mdi-action-settings:before {
    content: "\e660";
}
.mdi-action-settings-applications:before {
    content: "\e661";
}
.mdi-action-settings-backup-restore:before {
    content: "\e662";
}
.mdi-action-settings-bluetooth:before {
    content: "\e663";
}
.mdi-action-settings-cell:before {
    content: "\e664";
}
.mdi-action-settings-display:before {
    content: "\e665";
}
.mdi-action-settings-ethernet:before {
    content: "\e666";
}
.mdi-action-settings-input-antenna:before {
    content: "\e667";
}
.mdi-action-settings-input-component:before {
    content: "\e668";
}
.mdi-action-settings-input-composite:before {
    content: "\e669";
}
.mdi-action-settings-input-hdmi:before {
    content: "\e66a";
}
.mdi-action-settings-input-svideo:before {
    content: "\e66b";
}
.mdi-action-settings-overscan:before {
    content: "\e66c";
}
.mdi-action-settings-phone:before {
    content: "\e66d";
}
.mdi-action-settings-power:before {
    content: "\e66e";
}
.mdi-action-settings-remote:before {
    content: "\e66f";
}
.mdi-action-settings-voice:before {
    content: "\e670";
}
.mdi-action-shop:before {
    content: "\e671";
}
.mdi-action-shopping-basket:before {
    content: "\e672";
}
.mdi-action-shopping-cart:before {
    content: "\e673";
}
.mdi-action-shop-two:before {
    content: "\e674";
}
.mdi-action-speaker-notes:before {
    content: "\e675";
}
.mdi-action-spellcheck:before {
    content: "\e676";
}
.mdi-action-star-rate:before {
    content: "\e677";
}
.mdi-action-stars:before {
    content: "\e678";
}
.mdi-action-store:before {
    content: "\e679";
}
.mdi-action-subject:before {
    content: "\e67a";
}
.mdi-action-swap-horiz:before {
    content: "\e67b";
}
.mdi-action-swap-vert:before {
    content: "\e67c";
}
.mdi-action-swap-vert-circle:before {
    content: "\e67d";
}
.mdi-action-system-update-tv:before {
    content: "\e67e";
}
.mdi-action-tab:before {
    content: "\e67f";
}
.mdi-action-tab-unselected:before {
    content: "\e680";
}
.mdi-action-theaters:before {
    content: "\e681";
}
.mdi-action-thumb-down:before {
    content: "\e682";
}
.mdi-action-thumbs-up-down:before {
    content: "\e683";
}
.mdi-action-thumb-up:before {
    content: "\e684";
}
.mdi-action-toc:before {
    content: "\e685";
}
.mdi-action-today:before {
    content: "\e686";
}
.mdi-action-track-changes:before {
    content: "\e687";
}
.mdi-action-translate:before {
    content: "\e688";
}
.mdi-action-trending-down:before {
    content: "\e689";
}
.mdi-action-trending-neutral:before {
    content: "\e68a";
}
.mdi-action-trending-up:before {
    content: "\e68b";
}
.mdi-action-turned-in:before {
    content: "\e68c";
}
.mdi-action-turned-in-not:before {
    content: "\e68d";
}
.mdi-action-verified-user:before {
    content: "\e68e";
}
.mdi-action-view-agenda:before {
    content: "\e68f";
}
.mdi-action-view-array:before {
    content: "\e690";
}
.mdi-action-view-carousel:before {
    content: "\e691";
}
.mdi-action-view-column:before {
    content: "\e692";
}
.mdi-action-view-day:before {
    content: "\e693";
}
.mdi-action-view-headline:before {
    content: "\e694";
}
.mdi-action-view-list:before {
    content: "\e695";
}
.mdi-action-view-module:before {
    content: "\e696";
}
.mdi-action-view-quilt:before {
    content: "\e697";
}
.mdi-action-view-stream:before {
    content: "\e698";
}
.mdi-action-view-week:before {
    content: "\e699";
}
.mdi-action-visibility:before {
    content: "\e69a";
}
.mdi-action-visibility-off:before {
    content: "\e69b";
}
.mdi-action-wallet-giftcard:before {
    content: "\e69c";
}
.mdi-action-wallet-membership:before {
    content: "\e69d";
}
.mdi-action-wallet-travel:before {
    content: "\e69e";
}
.mdi-action-work:before {
    content: "\e69f";
}
.mdi-alert-error:before {
    content: "\e6a0";
}
.mdi-alert-warning:before {
    content: "\e6a1";
}
.mdi-av-album:before {
    content: "\e6a2";
}
.mdi-av-timer:before {
    content: "\e6a3";
}
.mdi-av-closed-caption:before {
    content: "\e6a4";
}
.mdi-av-equalizer:before {
    content: "\e6a5";
}
.mdi-av-explicit:before {
    content: "\e6a6";
}
.mdi-av-fast-forward:before {
    content: "\e6a7";
}
.mdi-av-fast-rewind:before {
    content: "\e6a8";
}
.mdi-av-games:before {
    content: "\e6a9";
}
.mdi-av-hearing:before {
    content: "\e6aa";
}
.mdi-av-high-quality:before {
    content: "\e6ab";
}
.mdi-av-loop:before {
    content: "\e6ac";
}
.mdi-av-mic:before {
    content: "\e6ad";
}
.mdi-av-mic-none:before {
    content: "\e6ae";
}
.mdi-av-mic-off:before {
    content: "\e6af";
}
.mdi-av-movie:before {
    content: "\e6b0";
}
.mdi-av-my-library-add:before {
    content: "\e6b1";
}
.mdi-av-my-library-books:before {
    content: "\e6b2";
}
.mdi-av-my-library-music:before {
    content: "\e6b3";
}
.mdi-av-new-releases:before {
    content: "\e6b4";
}
.mdi-av-not-interested:before {
    content: "\e6b5";
}
.mdi-av-pause:before {
    content: "\e6b6";
}
.mdi-av-pause-circle-fill:before {
    content: "\e6b7";
}
.mdi-av-pause-circle-outline:before {
    content: "\e6b8";
}
.mdi-av-play-arrow:before {
    content: "\e6b9";
}
.mdi-av-play-circle-fill:before {
    content: "\e6ba";
}
.mdi-av-play-circle-outline:before {
    content: "\e6bb";
}
.mdi-av-playlist-add:before {
    content: "\e6bc";
}
.mdi-av-play-shopping-bag:before {
    content: "\e6bd";
}
.mdi-av-queue:before {
    content: "\e6be";
}
.mdi-av-queue-music:before {
    content: "\e6bf";
}
.mdi-av-radio:before {
    content: "\e6c0";
}
.mdi-av-recent-actors:before {
    content: "\e6c1";
}
.mdi-av-repeat:before {
    content: "\e6c2";
}
.mdi-av-repeat-one:before {
    content: "\e6c3";
}
.mdi-av-replay:before {
    content: "\e6c4";
}
.mdi-av-shuffle:before {
    content: "\e6c5";
}
.mdi-av-skip-next:before {
    content: "\e6c6";
}
.mdi-av-skip-previous:before {
    content: "\e6c7";
}
.mdi-av-snooze:before {
    content: "\e6c8";
}
.mdi-av-stop:before {
    content: "\e6c9";
}
.mdi-av-subtitles:before {
    content: "\e6ca";
}
.mdi-av-surround-sound:before {
    content: "\e6cb";
}
.mdi-av-videocam:before {
    content: "\e6cc";
}
.mdi-av-videocam-off:before {
    content: "\e6cd";
}
.mdi-av-video-collection:before {
    content: "\e6ce";
}
.mdi-av-volume-down:before {
    content: "\e6cf";
}
.mdi-av-volume-mute:before {
    content: "\e6d0";
}
.mdi-av-volume-off:before {
    content: "\e6d1";
}
.mdi-av-volume-up:before {
    content: "\e6d2";
}
.mdi-av-web:before {
    content: "\e6d3";
}
.mdi-communication-business:before {
    content: "\e6d4";
}
.mdi-communication-call:before {
    content: "\e6d5";
}
.mdi-communication-call-end:before {
    content: "\e6d6";
}
.mdi-communication-call-made:before {
    content: "\e6d7";
}
.mdi-communication-call-merge:before {
    content: "\e6d8";
}
.mdi-communication-call-missed:before {
    content: "\e6d9";
}
.mdi-communication-call-received:before {
    content: "\e6da";
}
.mdi-communication-call-split:before {
    content: "\e6db";
}
.mdi-communication-chat:before {
    content: "\e6dc";
}
.mdi-communication-clear-all:before {
    content: "\e6dd";
}
.mdi-communication-comment:before {
    content: "\e6de";
}
.mdi-communication-contacts:before {
    content: "\e6df";
}
.mdi-communication-dialer-sip:before {
    content: "\e6e0";
}
.mdi-communication-dialpad:before {
    content: "\e6e1";
}
.mdi-communication-dnd-on:before {
    content: "\e6e2";
}
.mdi-communication-email:before {
    content: "\e6e3";
}
.mdi-communication-forum:before {
    content: "\e6e4";
}
.mdi-communication-import-export:before {
    content: "\e6e5";
}
.mdi-communication-invert-colors-off:before {
    content: "\e6e6";
}
.mdi-communication-invert-colors-on:before {
    content: "\e6e7";
}
.mdi-communication-live-help:before {
    content: "\e6e8";
}
.mdi-communication-location-off:before {
    content: "\e6e9";
}
.mdi-communication-location-on:before {
    content: "\e6ea";
}
.mdi-communication-message:before {
    content: "\e6eb";
}
.mdi-communication-messenger:before {
    content: "\e6ec";
}
.mdi-communication-no-sim:before {
    content: "\e6ed";
}
.mdi-communication-phone:before {
    content: "\e6ee";
}
.mdi-communication-portable-wifi-off:before {
    content: "\e6ef";
}
.mdi-communication-quick-contacts-dialer:before {
    content: "\e6f0";
}
.mdi-communication-quick-contacts-mail:before {
    content: "\e6f1";
}
.mdi-communication-ring-volume:before {
    content: "\e6f2";
}
.mdi-communication-stay-current-landscape:before {
    content: "\e6f3";
}
.mdi-communication-stay-current-portrait:before {
    content: "\e6f4";
}
.mdi-communication-stay-primary-landscape:before {
    content: "\e6f5";
}
.mdi-communication-stay-primary-portrait:before {
    content: "\e6f6";
}
.mdi-communication-swap-calls:before {
    content: "\e6f7";
}
.mdi-communication-textsms:before {
    content: "\e6f8";
}
.mdi-communication-voicemail:before {
    content: "\e6f9";
}
.mdi-communication-vpn-key:before {
    content: "\e6fa";
}
.mdi-content-add:before {
    content: "\e6fb";
}
.mdi-content-add-box:before {
    content: "\e6fc";
}
.mdi-content-add-circle:before {
    content: "\e6fd";
}
.mdi-content-add-circle-outline:before {
    content: "\e6fe";
}
.mdi-content-archive:before {
    content: "\e6ff";
}
.mdi-content-backspace:before {
    content: "\e700";
}
.mdi-content-block:before {
    content: "\e701";
}
.mdi-content-clear:before {
    content: "\e702";
}
.mdi-content-content-copy:before {
    content: "\e703";
}
.mdi-content-content-cut:before {
    content: "\e704";
}
.mdi-content-content-paste:before {
    content: "\e705";
}
.mdi-content-create:before {
    content: "\e706";
}
.mdi-content-drafts:before {
    content: "\e707";
}
.mdi-content-filter-list:before {
    content: "\e708";
}
.mdi-content-flag:before {
    content: "\e709";
}
.mdi-content-forward:before {
    content: "\e70a";
}
.mdi-content-gesture:before {
    content: "\e70b";
}
.mdi-content-inbox:before {
    content: "\e70c";
}
.mdi-content-link:before {
    content: "\e70d";
}
.mdi-content-mail:before {
    content: "\e70e";
}
.mdi-content-markunread:before {
    content: "\e70f";
}
.mdi-content-redo:before {
    content: "\e710";
}
.mdi-content-remove:before {
    content: "\e711";
}
.mdi-content-remove-circle:before {
    content: "\e712";
}
.mdi-content-remove-circle-outline:before {
    content: "\e713";
}
.mdi-content-reply:before {
    content: "\e714";
}
.mdi-content-reply-all:before {
    content: "\e715";
}
.mdi-content-report:before {
    content: "\e716";
}
.mdi-content-save:before {
    content: "\e717";
}
.mdi-content-select-all:before {
    content: "\e718";
}
.mdi-content-send:before {
    content: "\e719";
}
.mdi-content-sort:before {
    content: "\e71a";
}
.mdi-content-text-format:before {
    content: "\e71b";
}
.mdi-content-undo:before {
    content: "\e71c";
}
.mdi-device-access-alarm:before {
    content: "\e71d";
}
.mdi-device-access-alarms:before {
    content: "\e71e";
}
.mdi-device-access-time:before {
    content: "\e71f";
}
.mdi-device-add-alarm:before {
    content: "\e720";
}
.mdi-device-airplanemode-off:before {
    content: "\e721";
}
.mdi-device-airplanemode-on:before {
    content: "\e722";
}
.mdi-device-battery-20:before {
    content: "\e723";
}
.mdi-device-battery-30:before {
    content: "\e724";
}
.mdi-device-battery-50:before {
    content: "\e725";
}
.mdi-device-battery-60:before {
    content: "\e726";
}
.mdi-device-battery-80:before {
    content: "\e727";
}
.mdi-device-battery-90:before {
    content: "\e728";
}
.mdi-device-battery-alert:before {
    content: "\e729";
}
.mdi-device-battery-charging-20:before {
    content: "\e72a";
}
.mdi-device-battery-charging-30:before {
    content: "\e72b";
}
.mdi-device-battery-charging-50:before {
    content: "\e72c";
}
.mdi-device-battery-charging-60:before {
    content: "\e72d";
}
.mdi-device-battery-charging-80:before {
    content: "\e72e";
}
.mdi-device-battery-charging-90:before {
    content: "\e72f";
}
.mdi-device-battery-charging-full:before {
    content: "\e730";
}
.mdi-device-battery-full:before {
    content: "\e731";
}
.mdi-device-battery-std:before {
    content: "\e732";
}
.mdi-device-battery-unknown:before {
    content: "\e733";
}
.mdi-device-bluetooth:before {
    content: "\e734";
}
.mdi-device-bluetooth-connected:before {
    content: "\e735";
}
.mdi-device-bluetooth-disabled:before {
    content: "\e736";
}
.mdi-device-bluetooth-searching:before {
    content: "\e737";
}
.mdi-device-brightness-auto:before {
    content: "\e738";
}
.mdi-device-brightness-high:before {
    content: "\e739";
}
.mdi-device-brightness-low:before {
    content: "\e73a";
}
.mdi-device-brightness-medium:before {
    content: "\e73b";
}
.mdi-device-data-usage:before {
    content: "\e73c";
}
.mdi-device-developer-mode:before {
    content: "\e73d";
}
.mdi-device-devices:before {
    content: "\e73e";
}
.mdi-device-dvr:before {
    content: "\e73f";
}
.mdi-device-gps-fixed:before {
    content: "\e740";
}
.mdi-device-gps-not-fixed:before {
    content: "\e741";
}
.mdi-device-gps-off:before {
    content: "\e742";
}
.mdi-device-location-disabled:before {
    content: "\e743";
}
.mdi-device-location-searching:before {
    content: "\e744";
}
.mdi-device-multitrack-audio:before {
    content: "\e745";
}
.mdi-device-network-cell:before {
    content: "\e746";
}
.mdi-device-network-wifi:before {
    content: "\e747";
}
.mdi-device-nfc:before {
    content: "\e748";
}
.mdi-device-now-wallpaper:before {
    content: "\e749";
}
.mdi-device-now-widgets:before {
    content: "\e74a";
}
.mdi-device-screen-lock-landscape:before {
    content: "\e74b";
}
.mdi-device-screen-lock-portrait:before {
    content: "\e74c";
}
.mdi-device-screen-lock-rotation:before {
    content: "\e74d";
}
.mdi-device-screen-rotation:before {
    content: "\e74e";
}
.mdi-device-sd-storage:before {
    content: "\e74f";
}
.mdi-device-settings-system-daydream:before {
    content: "\e750";
}
.mdi-device-signal-cellular-0-bar:before {
    content: "\e751";
}
.mdi-device-signal-cellular-1-bar:before {
    content: "\e752";
}
.mdi-device-signal-cellular-2-bar:before {
    content: "\e753";
}
.mdi-device-signal-cellular-3-bar:before {
    content: "\e754";
}
.mdi-device-signal-cellular-4-bar:before {
    content: "\e755";
}
.mdi-device-signal-cellular-connected-no-internet-0-bar:before {
    content: "\e756";
}
.mdi-device-signal-cellular-connected-no-internet-1-bar:before {
    content: "\e757";
}
.mdi-device-signal-cellular-connected-no-internet-2-bar:before {
    content: "\e758";
}
.mdi-device-signal-cellular-connected-no-internet-3-bar:before {
    content: "\e759";
}
.mdi-device-signal-cellular-connected-no-internet-4-bar:before {
    content: "\e75a";
}
.mdi-device-signal-cellular-no-sim:before {
    content: "\e75b";
}
.mdi-device-signal-cellular-null:before {
    content: "\e75c";
}
.mdi-device-signal-cellular-off:before {
    content: "\e75d";
}
.mdi-device-signal-wifi-0-bar:before {
    content: "\e75e";
}
.mdi-device-signal-wifi-1-bar:before {
    content: "\e75f";
}
.mdi-device-signal-wifi-2-bar:before {
    content: "\e760";
}
.mdi-device-signal-wifi-3-bar:before {
    content: "\e761";
}
.mdi-device-signal-wifi-4-bar:before {
    content: "\e762";
}
.mdi-device-signal-wifi-off:before {
    content: "\e763";
}
.mdi-device-storage:before {
    content: "\e764";
}
.mdi-device-usb:before {
    content: "\e765";
}
.mdi-device-wifi-lock:before {
    content: "\e766";
}
.mdi-device-wifi-tethering:before {
    content: "\e767";
}
.mdi-editor-attach-file:before {
    content: "\e768";
}
.mdi-editor-attach-money:before {
    content: "\e769";
}
.mdi-editor-border-all:before {
    content: "\e76a";
}
.mdi-editor-border-bottom:before {
    content: "\e76b";
}
.mdi-editor-border-clear:before {
    content: "\e76c";
}
.mdi-editor-border-color:before {
    content: "\e76d";
}
.mdi-editor-border-horizontal:before {
    content: "\e76e";
}
.mdi-editor-border-inner:before {
    content: "\e76f";
}
.mdi-editor-border-left:before {
    content: "\e770";
}
.mdi-editor-border-outer:before {
    content: "\e771";
}
.mdi-editor-border-right:before {
    content: "\e772";
}
.mdi-editor-border-style:before {
    content: "\e773";
}
.mdi-editor-border-top:before {
    content: "\e774";
}
.mdi-editor-border-vertical:before {
    content: "\e775";
}
.mdi-editor-format-align-center:before {
    content: "\e776";
}
.mdi-editor-format-align-justify:before {
    content: "\e777";
}
.mdi-editor-format-align-left:before {
    content: "\e778";
}
.mdi-editor-format-align-right:before {
    content: "\e779";
}
.mdi-editor-format-bold:before {
    content: "\e77a";
}
.mdi-editor-format-clear:before {
    content: "\e77b";
}
.mdi-editor-format-color-fill:before {
    content: "\e77c";
}
.mdi-editor-format-color-reset:before {
    content: "\e77d";
}
.mdi-editor-format-color-text:before {
    content: "\e77e";
}
.mdi-editor-format-indent-decrease:before {
    content: "\e77f";
}
.mdi-editor-format-indent-increase:before {
    content: "\e780";
}
.mdi-editor-format-italic:before {
    content: "\e781";
}
.mdi-editor-format-line-spacing:before {
    content: "\e782";
}
.mdi-editor-format-list-bulleted:before {
    content: "\e783";
}
.mdi-editor-format-list-numbered:before {
    content: "\e784";
}
.mdi-editor-format-paint:before {
    content: "\e785";
}
.mdi-editor-format-quote:before {
    content: "\e786";
}
.mdi-editor-format-size:before {
    content: "\e787";
}
.mdi-editor-format-strikethrough:before {
    content: "\e788";
}
.mdi-editor-format-textdirection-l-to-r:before {
    content: "\e789";
}
.mdi-editor-format-textdirection-r-to-l:before {
    content: "\e78a";
}
.mdi-editor-format-underline:before {
    content: "\e78b";
}
.mdi-editor-functions:before {
    content: "\e78c";
}
.mdi-editor-insert-chart:before {
    content: "\e78d";
}
.mdi-editor-insert-comment:before {
    content: "\e78e";
}
.mdi-editor-insert-drive-file:before {
    content: "\e78f";
}
.mdi-editor-insert-emoticon:before {
    content: "\e790";
}
.mdi-editor-insert-invitation:before {
    content: "\e791";
}
.mdi-editor-insert-link:before {
    content: "\e792";
}
.mdi-editor-insert-photo:before {
    content: "\e793";
}
.mdi-editor-merge-type:before {
    content: "\e794";
}
.mdi-editor-mode-comment:before {
    content: "\e795";
}
.mdi-editor-mode-edit:before {
    content: "\e796";
}
.mdi-editor-publish:before {
    content: "\e797";
}
.mdi-editor-vertical-align-bottom:before {
    content: "\e798";
}
.mdi-editor-vertical-align-center:before {
    content: "\e799";
}
.mdi-editor-vertical-align-top:before {
    content: "\e79a";
}
.mdi-editor-wrap-text:before {
    content: "\e79b";
}
.mdi-file-attachment:before {
    content: "\e79c";
}
.mdi-file-cloud:before {
    content: "\e79d";
}
.mdi-file-cloud-circle:before {
    content: "\e79e";
}
.mdi-file-cloud-done:before {
    content: "\e79f";
}
.mdi-file-cloud-download:before {
    content: "\e7a0";
}
.mdi-file-cloud-off:before {
    content: "\e7a1";
}
.mdi-file-cloud-queue:before {
    content: "\e7a2";
}
.mdi-file-cloud-upload:before {
    content: "\e7a3";
}
.mdi-file-file-download:before {
    content: "\e7a4";
}
.mdi-file-file-upload:before {
    content: "\e7a5";
}
.mdi-file-folder:before {
    content: "\e7a6";
}
.mdi-file-folder-open:before {
    content: "\e7a7";
}
.mdi-file-folder-shared:before {
    content: "\e7a8";
}
.mdi-hardware-cast:before {
    content: "\e7a9";
}
.mdi-hardware-cast-connected:before {
    content: "\e7aa";
}
.mdi-hardware-computer:before {
    content: "\e7ab";
}
.mdi-hardware-desktop-mac:before {
    content: "\e7ac";
}
.mdi-hardware-desktop-windows:before {
    content: "\e7ad";
}
.mdi-hardware-dock:before {
    content: "\e7ae";
}
.mdi-hardware-gamepad:before {
    content: "\e7af";
}
.mdi-hardware-headset:before {
    content: "\e7b0";
}
.mdi-hardware-headset-mic:before {
    content: "\e7b1";
}
.mdi-hardware-keyboard:before {
    content: "\e7b2";
}
.mdi-hardware-keyboard-alt:before {
    content: "\e7b3";
}
.mdi-hardware-keyboard-arrow-down:before {
    content: "\e7b4";
}
.mdi-hardware-keyboard-arrow-left:before {
    content: "\e7b5";
}
.mdi-hardware-keyboard-arrow-right:before {
    content: "\e7b6";
}
.mdi-hardware-keyboard-arrow-up:before {
    content: "\e7b7";
}
.mdi-hardware-keyboard-backspace:before {
    content: "\e7b8";
}
.mdi-hardware-keyboard-capslock:before {
    content: "\e7b9";
}
.mdi-hardware-keyboard-control:before {
    content: "\e7ba";
}
.mdi-hardware-keyboard-hide:before {
    content: "\e7bb";
}
.mdi-hardware-keyboard-return:before {
    content: "\e7bc";
}
.mdi-hardware-keyboard-tab:before {
    content: "\e7bd";
}
.mdi-hardware-keyboard-voice:before {
    content: "\e7be";
}
.mdi-hardware-laptop:before {
    content: "\e7bf";
}
.mdi-hardware-laptop-chromebook:before {
    content: "\e7c0";
}
.mdi-hardware-laptop-mac:before {
    content: "\e7c1";
}
.mdi-hardware-laptop-windows:before {
    content: "\e7c2";
}
.mdi-hardware-memory:before {
    content: "\e7c3";
}
.mdi-hardware-mouse:before {
    content: "\e7c4";
}
.mdi-hardware-phone-android:before {
    content: "\e7c5";
}
.mdi-hardware-phone-iphone:before {
    content: "\e7c6";
}
.mdi-hardware-phonelink:before {
    content: "\e7c7";
}
.mdi-hardware-phonelink-off:before {
    content: "\e7c8";
}
.mdi-hardware-security:before {
    content: "\e7c9";
}
.mdi-hardware-sim-card:before {
    content: "\e7ca";
}
.mdi-hardware-smartphone:before {
    content: "\e7cb";
}
.mdi-hardware-speaker:before {
    content: "\e7cc";
}
.mdi-hardware-tablet:before {
    content: "\e7cd";
}
.mdi-hardware-tablet-android:before {
    content: "\e7ce";
}
.mdi-hardware-tablet-mac:before {
    content: "\e7cf";
}
.mdi-hardware-tv:before {
    content: "\e7d0";
}
.mdi-hardware-watch:before {
    content: "\e7d1";
}
.mdi-image-add-to-photos:before {
    content: "\e7d2";
}
.mdi-image-adjust:before {
    content: "\e7d3";
}
.mdi-image-assistant-photo:before {
    content: "\e7d4";
}
.mdi-image-audiotrack:before {
    content: "\e7d5";
}
.mdi-image-blur-circular:before {
    content: "\e7d6";
}
.mdi-image-blur-linear:before {
    content: "\e7d7";
}
.mdi-image-blur-off:before {
    content: "\e7d8";
}
.mdi-image-blur-on:before {
    content: "\e7d9";
}
.mdi-image-brightness-1:before {
    content: "\e7da";
}
.mdi-image-brightness-2:before {
    content: "\e7db";
}
.mdi-image-brightness-3:before {
    content: "\e7dc";
}
.mdi-image-brightness-4:before {
    content: "\e7dd";
}
.mdi-image-brightness-5:before {
    content: "\e7de";
}
.mdi-image-brightness-6:before {
    content: "\e7df";
}
.mdi-image-brightness-7:before {
    content: "\e7e0";
}
.mdi-image-brush:before {
    content: "\e7e1";
}
.mdi-image-camera:before {
    content: "\e7e2";
}
.mdi-image-camera-alt:before {
    content: "\e7e3";
}
.mdi-image-camera-front:before {
    content: "\e7e4";
}
.mdi-image-camera-rear:before {
    content: "\e7e5";
}
.mdi-image-camera-roll:before {
    content: "\e7e6";
}
.mdi-image-center-focus-strong:before {
    content: "\e7e7";
}
.mdi-image-center-focus-weak:before {
    content: "\e7e8";
}
.mdi-image-collections:before {
    content: "\e7e9";
}
.mdi-image-colorize:before {
    content: "\e7ea";
}
.mdi-image-color-lens:before {
    content: "\e7eb";
}
.mdi-image-compare:before {
    content: "\e7ec";
}
.mdi-image-control-point:before {
    content: "\e7ed";
}
.mdi-image-control-point-duplicate:before {
    content: "\e7ee";
}
.mdi-image-crop:before {
    content: "\e7ef";
}
.mdi-image-crop-3-2:before {
    content: "\e7f0";
}
.mdi-image-crop-5-4:before {
    content: "\e7f1";
}
.mdi-image-crop-7-5:before {
    content: "\e7f2";
}
.mdi-image-crop-16-9:before {
    content: "\e7f3";
}
.mdi-image-crop-din:before {
    content: "\e7f4";
}
.mdi-image-crop-free:before {
    content: "\e7f5";
}
.mdi-image-crop-landscape:before {
    content: "\e7f6";
}
.mdi-image-crop-original:before {
    content: "\e7f7";
}
.mdi-image-crop-portrait:before {
    content: "\e7f8";
}
.mdi-image-crop-square:before {
    content: "\e7f9";
}
.mdi-image-dehaze:before {
    content: "\e7fa";
}
.mdi-image-details:before {
    content: "\e7fb";
}
.mdi-image-edit:before {
    content: "\e7fc";
}
.mdi-image-exposure:before {
    content: "\e7fd";
}
.mdi-image-exposure-minus-1:before {
    content: "\e7fe";
}
.mdi-image-exposure-minus-2:before {
    content: "\e7ff";
}
.mdi-image-exposure-plus-1:before {
    content: "\e800";
}
.mdi-image-exposure-plus-2:before {
    content: "\e801";
}
.mdi-image-exposure-zero:before {
    content: "\e802";
}
.mdi-image-filter:before {
    content: "\e803";
}
.mdi-image-filter-1:before {
    content: "\e804";
}
.mdi-image-filter-2:before {
    content: "\e805";
}
.mdi-image-filter-3:before {
    content: "\e806";
}
.mdi-image-filter-4:before {
    content: "\e807";
}
.mdi-image-filter-5:before {
    content: "\e808";
}
.mdi-image-filter-6:before {
    content: "\e809";
}
.mdi-image-filter-7:before {
    content: "\e80a";
}
.mdi-image-filter-8:before {
    content: "\e80b";
}
.mdi-image-filter-9:before {
    content: "\e80c";
}
.mdi-image-filter-9-plus:before {
    content: "\e80d";
}
.mdi-image-filter-b-and-w:before {
    content: "\e80e";
}
.mdi-image-filter-center-focus:before {
    content: "\e80f";
}
.mdi-image-filter-drama:before {
    content: "\e810";
}
.mdi-image-filter-frames:before {
    content: "\e811";
}
.mdi-image-filter-hdr:before {
    content: "\e812";
}
.mdi-image-filter-none:before {
    content: "\e813";
}
.mdi-image-filter-tilt-shift:before {
    content: "\e814";
}
.mdi-image-filter-vintage:before {
    content: "\e815";
}
.mdi-image-flare:before {
    content: "\e816";
}
.mdi-image-flash-auto:before {
    content: "\e817";
}
.mdi-image-flash-off:before {
    content: "\e818";
}
.mdi-image-flash-on:before {
    content: "\e819";
}
.mdi-image-flip:before {
    content: "\e81a";
}
.mdi-image-gradient:before {
    content: "\e81b";
}
.mdi-image-grain:before {
    content: "\e81c";
}
.mdi-image-grid-off:before {
    content: "\e81d";
}
.mdi-image-grid-on:before {
    content: "\e81e";
}
.mdi-image-hdr-off:before {
    content: "\e81f";
}
.mdi-image-hdr-on:before {
    content: "\e820";
}
.mdi-image-hdr-strong:before {
    content: "\e821";
}
.mdi-image-hdr-weak:before {
    content: "\e822";
}
.mdi-image-healing:before {
    content: "\e823";
}
.mdi-image-image:before {
    content: "\e824";
}
.mdi-image-image-aspect-ratio:before {
    content: "\e825";
}
.mdi-image-iso:before {
    content: "\e826";
}
.mdi-image-landscape:before {
    content: "\e827";
}
.mdi-image-leak-add:before {
    content: "\e828";
}
.mdi-image-leak-remove:before {
    content: "\e829";
}
.mdi-image-lens:before {
    content: "\e82a";
}
.mdi-image-looks:before {
    content: "\e82b";
}
.mdi-image-looks-3:before {
    content: "\e82c";
}
.mdi-image-looks-4:before {
    content: "\e82d";
}
.mdi-image-looks-5:before {
    content: "\e82e";
}
.mdi-image-looks-6:before {
    content: "\e82f";
}
.mdi-image-looks-one:before {
    content: "\e830";
}
.mdi-image-looks-two:before {
    content: "\e831";
}
.mdi-image-loupe:before {
    content: "\e832";
}
.mdi-image-movie-creation:before {
    content: "\e833";
}
.mdi-image-nature:before {
    content: "\e834";
}
.mdi-image-nature-people:before {
    content: "\e835";
}
.mdi-image-navigate-before:before {
    content: "\e836";
}
.mdi-image-navigate-next:before {
    content: "\e837";
}
.mdi-image-palette:before {
    content: "\e838";
}
.mdi-image-panorama:before {
    content: "\e839";
}
.mdi-image-panorama-fisheye:before {
    content: "\e83a";
}
.mdi-image-panorama-horizontal:before {
    content: "\e83b";
}
.mdi-image-panorama-vertical:before {
    content: "\e83c";
}
.mdi-image-panorama-wide-angle:before {
    content: "\e83d";
}
.mdi-image-photo:before {
    content: "\e83e";
}
.mdi-image-photo-album:before {
    content: "\e83f";
}
.mdi-image-photo-camera:before {
    content: "\e840";
}
.mdi-image-photo-library:before {
    content: "\e841";
}
.mdi-image-portrait:before {
    content: "\e842";
}
.mdi-image-remove-red-eye:before {
    content: "\e843";
}
.mdi-image-rotate-left:before {
    content: "\e844";
}
.mdi-image-rotate-right:before {
    content: "\e845";
}
.mdi-image-slideshow:before {
    content: "\e846";
}
.mdi-image-straighten:before {
    content: "\e847";
}
.mdi-image-style:before {
    content: "\e848";
}
.mdi-image-switch-camera:before {
    content: "\e849";
}
.mdi-image-switch-video:before {
    content: "\e84a";
}
.mdi-image-tag-faces:before {
    content: "\e84b";
}
.mdi-image-texture:before {
    content: "\e84c";
}
.mdi-image-timelapse:before {
    content: "\e84d";
}
.mdi-image-timer:before {
    content: "\e84e";
}
.mdi-image-timer-3:before {
    content: "\e84f";
}
.mdi-image-timer-10:before {
    content: "\e850";
}
.mdi-image-timer-auto:before {
    content: "\e851";
}
.mdi-image-timer-off:before {
    content: "\e852";
}
.mdi-image-tonality:before {
    content: "\e853";
}
.mdi-image-transform:before {
    content: "\e854";
}
.mdi-image-tune:before {
    content: "\e855";
}
.mdi-image-wb-auto:before {
    content: "\e856";
}
.mdi-image-wb-cloudy:before {
    content: "\e857";
}
.mdi-image-wb-incandescent:before {
    content: "\e858";
}
.mdi-image-wb-irradescent:before {
    content: "\e859";
}
.mdi-image-wb-sunny:before {
    content: "\e85a";
}
.mdi-maps-beenhere:before {
    content: "\e85b";
}
.mdi-maps-directions:before {
    content: "\e85c";
}
.mdi-maps-directions-bike:before {
    content: "\e85d";
}
.mdi-maps-directions-bus:before {
    content: "\e85e";
}
.mdi-maps-directions-car:before {
    content: "\e85f";
}
.mdi-maps-directions-ferry:before {
    content: "\e860";
}
.mdi-maps-directions-subway:before {
    content: "\e861";
}
.mdi-maps-directions-train:before {
    content: "\e862";
}
.mdi-maps-directions-transit:before {
    content: "\e863";
}
.mdi-maps-directions-walk:before {
    content: "\e864";
}
.mdi-maps-flight:before {
    content: "\e865";
}
.mdi-maps-hotel:before {
    content: "\e866";
}
.mdi-maps-layers:before {
    content: "\e867";
}
.mdi-maps-layers-clear:before {
    content: "\e868";
}
.mdi-maps-local-airport:before {
    content: "\e869";
}
.mdi-maps-local-atm:before {
    content: "\e86a";
}
.mdi-maps-local-attraction:before {
    content: "\e86b";
}
.mdi-maps-local-bar:before {
    content: "\e86c";
}
.mdi-maps-local-cafe:before {
    content: "\e86d";
}
.mdi-maps-local-car-wash:before {
    content: "\e86e";
}
.mdi-maps-local-convenience-store:before {
    content: "\e86f";
}
.mdi-maps-local-drink:before {
    content: "\e870";
}
.mdi-maps-local-florist:before {
    content: "\e871";
}
.mdi-maps-local-gas-station:before {
    content: "\e872";
}
.mdi-maps-local-grocery-store:before {
    content: "\e873";
}
.mdi-maps-local-hospital:before {
    content: "\e874";
}
.mdi-maps-local-hotel:before {
    content: "\e875";
}
.mdi-maps-local-laundry-service:before {
    content: "\e876";
}
.mdi-maps-local-library:before {
    content: "\e877";
}
.mdi-maps-local-mall:before {
    content: "\e878";
}
.mdi-maps-local-movies:before {
    content: "\e879";
}
.mdi-maps-local-offer:before {
    content: "\e87a";
}
.mdi-maps-local-parking:before {
    content: "\e87b";
}
.mdi-maps-local-pharmacy:before {
    content: "\e87c";
}
.mdi-maps-local-phone:before {
    content: "\e87d";
}
.mdi-maps-local-pizza:before {
    content: "\e87e";
}
.mdi-maps-local-play:before {
    content: "\e87f";
}
.mdi-maps-local-post-office:before {
    content: "\e880";
}
.mdi-maps-local-print-shop:before {
    content: "\e881";
}
.mdi-maps-local-restaurant:before {
    content: "\e882";
}
.mdi-maps-local-see:before {
    content: "\e883";
}
.mdi-maps-local-shipping:before {
    content: "\e884";
}
.mdi-maps-local-taxi:before {
    content: "\e885";
}
.mdi-maps-location-history:before {
    content: "\e886";
}
.mdi-maps-map:before {
    content: "\e887";
}
.mdi-maps-my-location:before {
    content: "\e888";
}
.mdi-maps-navigation:before {
    content: "\e889";
}
.mdi-maps-pin-drop:before {
    content: "\e88a";
}
.mdi-maps-place:before {
    content: "\e88b";
}
.mdi-maps-rate-review:before {
    content: "\e88c";
}
.mdi-maps-restaurant-menu:before {
    content: "\e88d";
}
.mdi-maps-satellite:before {
    content: "\e88e";
}
.mdi-maps-store-mall-directory:before {
    content: "\e88f";
}
.mdi-maps-terrain:before {
    content: "\e890";
}
.mdi-maps-traffic:before {
    content: "\e891";
}
.mdi-navigation-apps:before {
    content: "\e892";
}
.mdi-navigation-arrow-back:before {
    content: "\e893";
}
.mdi-navigation-arrow-drop-down:before {
    content: "\e894";
}
.mdi-navigation-arrow-drop-down-circle:before {
    content: "\e895";
}
.mdi-navigation-arrow-drop-up:before {
    content: "\e896";
}
.mdi-navigation-arrow-forward:before {
    content: "\e897";
}
.mdi-navigation-cancel:before {
    content: "\e898";
}
.mdi-navigation-check:before {
    content: "\e899";
}
.mdi-navigation-chevron-left:before {
    content: "\e89a";
}
.mdi-navigation-chevron-right:before {
    content: "\e89b";
}
.mdi-navigation-close:before {
    content: "\e89c";
}
.mdi-navigation-expand-less:before {
    content: "\e89d";
}
.mdi-navigation-expand-more:before {
    content: "\e89e";
}
.mdi-navigation-fullscreen:before {
    content: "\e89f";
}
.mdi-navigation-fullscreen-exit:before {
    content: "\e8a0";
}
.mdi-navigation-menu:before {
    content: "\e8a1";
}
.mdi-navigation-more-horiz:before {
    content: "\e8a2";
}
.mdi-navigation-more-vert:before {
    content: "\e8a3";
}
.mdi-navigation-refresh:before {
    content: "\e8a4";
}
.mdi-navigation-unfold-less:before {
    content: "\e8a5";
}
.mdi-navigation-unfold-more:before {
    content: "\e8a6";
}
.mdi-notification-adb:before {
    content: "\e8a7";
}
.mdi-notification-bluetooth-audio:before {
    content: "\e8a8";
}
.mdi-notification-disc-full:before {
    content: "\e8a9";
}
.mdi-notification-dnd-forwardslash:before {
    content: "\e8aa";
}
.mdi-notification-do-not-disturb:before {
    content: "\e8ab";
}
.mdi-notification-drive-eta:before {
    content: "\e8ac";
}
.mdi-notification-event-available:before {
    content: "\e8ad";
}
.mdi-notification-event-busy:before {
    content: "\e8ae";
}
.mdi-notification-event-note:before {
    content: "\e8af";
}
.mdi-notification-folder-special:before {
    content: "\e8b0";
}
.mdi-notification-mms:before {
    content: "\e8b1";
}
.mdi-notification-more:before {
    content: "\e8b2";
}
.mdi-notification-network-locked:before {
    content: "\e8b3";
}
.mdi-notification-phone-bluetooth-speaker:before {
    content: "\e8b4";
}
.mdi-notification-phone-forwarded:before {
    content: "\e8b5";
}
.mdi-notification-phone-in-talk:before {
    content: "\e8b6";
}
.mdi-notification-phone-locked:before {
    content: "\e8b7";
}
.mdi-notification-phone-missed:before {
    content: "\e8b8";
}
.mdi-notification-phone-paused:before {
    content: "\e8b9";
}
.mdi-notification-play-download:before {
    content: "\e8ba";
}
.mdi-notification-play-install:before {
    content: "\e8bb";
}
.mdi-notification-sd-card:before {
    content: "\e8bc";
}
.mdi-notification-sim-card-alert:before {
    content: "\e8bd";
}
.mdi-notification-sms:before {
    content: "\e8be";
}
.mdi-notification-sms-failed:before {
    content: "\e8bf";
}
.mdi-notification-sync:before {
    content: "\e8c0";
}
.mdi-notification-sync-disabled:before {
    content: "\e8c1";
}
.mdi-notification-sync-problem:before {
    content: "\e8c2";
}
.mdi-notification-system-update:before {
    content: "\e8c3";
}
.mdi-notification-tap-and-play:before {
    content: "\e8c4";
}
.mdi-notification-time-to-leave:before {
    content: "\e8c5";
}
.mdi-notification-vibration:before {
    content: "\e8c6";
}
.mdi-notification-voice-chat:before {
    content: "\e8c7";
}
.mdi-notification-vpn-lock:before {
    content: "\e8c8";
}
.mdi-social-cake:before {
    content: "\e8c9";
}
.mdi-social-domain:before {
    content: "\e8ca";
}
.mdi-social-group:before {
    content: "\e8cb";
}
.mdi-social-group-add:before {
    content: "\e8cc";
}
.mdi-social-location-city:before {
    content: "\e8cd";
}
.mdi-social-mood:before {
    content: "\e8ce";
}
.mdi-social-notifications:before {
    content: "\e8cf";
}
.mdi-social-notifications-none:before {
    content: "\e8d0";
}
.mdi-social-notifications-off:before {
    content: "\e8d1";
}
.mdi-social-notifications-on:before {
    content: "\e8d2";
}
.mdi-social-notifications-paused:before {
    content: "\e8d3";
}
.mdi-social-pages:before {
    content: "\e8d4";
}
.mdi-social-party-mode:before {
    content: "\e8d5";
}
.mdi-social-people:before {
    content: "\e8d6";
}
.mdi-social-people-outline:before {
    content: "\e8d7";
}
.mdi-social-person:before {
    content: "\e8d8";
}
.mdi-social-person-add:before {
    content: "\e8d9";
}
.mdi-social-person-outline:before {
    content: "\e8da";
}
.mdi-social-plus-one:before {
    content: "\e8db";
}
.mdi-social-poll:before {
    content: "\e8dc";
}
.mdi-social-public:before {
    content: "\e8dd";
}
.mdi-social-school:before {
    content: "\e8de";
}
.mdi-social-share:before {
    content: "\e8df";
}
.mdi-social-whatshot:before {
    content: "\e8e0";
}
.mdi-toggle-check-box:before {
    content: "\e8e1";
}
.mdi-toggle-check-box-outline-blank:before {
    content: "\e8e2";
}
.mdi-toggle-radio-button-off:before {
    content: "\e8e3";
}
.mdi-toggle-radio-button-on:before {
    content: "\e8e4";
}
