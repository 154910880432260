.dropdownjs:after {
    right: 5px;
    top: 3px;
    font-size: 25px;
    position: absolute;
    content: "\e894";
    font-family: "Material-Design-Icons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    pointer-events: none;
    color: #757575;

}
